<template>
  <div class="home">
    <button
      class="button field is-info is-light"
      @click="isCardModalActive = true"
    >
      <b-icon icon="plus"></b-icon>
      <span>Novo cargo</span>
    </button>
    <b-table
      :data="allRoles"
      ref="table"
      paginated
      per-page="10"
      detailed
      detail-key="id"
      :striped="true"
      :debounce-search="1000"
      @details-open="
        (row) => $buefy.toast.open(`Visualizando os detalhes de ${row.name}`)
      "
      :show-detail-icon="true"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column field="id" label="ID" width="40" numeric v-slot="props">{{
        props.row.id
      }}</b-table-column>

      <b-table-column
        field="name"
        label="Nome"
        :searchable="true"
        sortable
        v-slot="props"
      >
        <template v-if="showDetailIcon">{{ props.row.name }}</template>
        <template v-else>
          <a @click="props.toggleDetails(props.row)">{{ props.row.name }}</a>
        </template>
      </b-table-column>

      <b-table-column
        field="slug"
        label="Apelido"
        sortable
        :searchable="true"
        v-slot="props"
        >{{ props.row.slug }}</b-table-column
      >

      <b-table-column v-slot="props" label="Opções">
        <button
          class="button is-small is-light"
          @click.prevent="
            edit(props.row)
            isCardModalActive = true
          "
        >
          <b-icon icon="pen" size="is-small"></b-icon>
        </button>
        <button
          class="button is-small is-danger"
          @click.prevent="onDelete(props.row.id)"
        >
          <b-icon icon="delete" size="is-small"></b-icon>
        </button>
      </b-table-column>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <strong>{{ props.row.name }}</strong>
                <br />
                <strong>Apelido:</strong>
                <small>{{ props.row.slug }}</small>
                <br />
              </p>
            </div>
          </div>
        </article>
      </template>
    </b-table>
    <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <b-field label="Nome *">
              <b-input v-model="model.name" required></b-input>
            </b-field>
            <b-field label="Apelido *">
              <b-input v-model="model.slug" required></b-input>
            </b-field>
            <b-field label="Permissões * ">
              <v-multiselect-listbox
                :options="allPermissions"
                :reduce-display-property="(option) => option.name"
                :reduce-value-property="(option) => option.id"
                search-options-placeholder="Filtrar não selecionadas"
                selected-options-placeholder="Filtrar selecionadas"
                no-options-text="Nenhuma permissão"
                no-options-found-text="Nenhuma permissão encontrada"
                selected-no-options-found-text="Nenhuma permissão encontrada"
                selected-no-options-text="Nenhuma permissão selecionada"
                v-model="model.permissions"
                search-input-class="custom-input-class"
              ></v-multiselect-listbox>
            </b-field>
            <b-field label="Descrição">
              <b-input
                v-model="model.description"
                maxlength="200"
                type="textarea"
              ></b-input>
            </b-field>
            <b-button type="button field is-info" class="mt-10" @click="save"
              >Salvar</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import service from '../features/roles/store/service'
import Role from '../models/role'

export default {
  name: 'Roles',
  data() {
    return {
      model: Role.model,
      isModalVisible: false,
      showDetailIcon: false,
      isCardModalActive: false,
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('roles', ['allRoles']),
    ...mapState('permissions', ['allPermissions']),
  },
  methods: {
    ...mapActions('roles', ['getAllRoles']),
    ...mapActions('permissions', ['getAllPermissions']),
    async init() {
      await this.getAllRoles()
      await this.getAllPermissions()
    },
    async save() {
      const role = { ...this.model }

      console.log(role)

      role.permissions = role.permissions.map((x) => {
        return x.id
      })

      try {
        const save = role.id
          ? await service.updateRole(role)
          : await service.saveRole(role)

        await this.$success('Cargo')
        await location.reload(true)

        this.model = Role.model
      } catch (error) {
        this.$error(
          error.response.data.message || error.response.data[0].message
        )
      }
    },
    async onDelete(id) {
      try {
        await service.deleteRole(id)

        await this.$delete('Cargo')
        location.reload(true)
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
    edit(item) {
      console.log(item)
      this.model = { ...item }
    },
    showModal() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
      this.model = {}
    },
  },
}
</script>

<style scoped></style>
<style src="vue-multiselect-listbox/dist/vue-multi-select-listbox.css"></style>
