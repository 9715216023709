module.exports = {
  model: { id: '', slug: '', name: '', description: '', permissions: [] },
  schema: {
    fields: [
      {
        type: 'custom-input',
        options: { uppercaseFirstLetter: true },
        label: 'Nome',
        model: 'name',
        placeholder: 'Ex: Administrador',
        required: true,
      },
      {
        type: 'custom-input',
        options: { lowercase: true },
        label: 'Apelido',
        model: 'slug',
        placeholder: 'Ex: admin',
        required: true,
      },
      {
        type: 'custom-input',
        inputType: 'textArea',
        options: { uppercaseFirstLetter: true },
        label: 'Descrição',
        model: 'description',
        placeholder: 'Ex: Administrador do sistema',
        required: true,
      },
      {
        type: 'list-box',
        label: 'Permissões',
        model: 'permissions',
        options: [],
      },
    ],
  },
  columns: [
    { key: 'slug', label: 'Apelido', filter: true },
    { key: 'name', label: 'Nome', filter: true },
    { key: 'description', label: 'Descrição', filter: false },
  ],
  viewDataTypes: [
    { key: 'slug', label: 'Apelido' },
    { key: 'name', label: 'Nome' },
    { key: 'description', label: 'Descrição' },
    {
      key: 'permissions',
      label: 'Permissões',
      array: true,
      multiple: true,
      keys: [{ key: 'name', label: 'Nome' }],
    },
  ],
}
